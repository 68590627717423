.markdownContent {
    display: flex;
    flex-wrap: wrap !important;
    /* word-break: break-all; */
    /* word-break: keep-all; */
    word-wrap: break-word;
    white-space: pre-line;
    /* min-width: fit-content; */
    /* word-spacing: 2%; */
    /* white-space : pre-wrap !important; */
}

.gmnoprint > div, .gm-bundled-control > div ,.gm-bundled-control-on-bottom > div {
    display: none !important;
}